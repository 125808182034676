<template>
<div class="mainform">
  <div class="form">
    <el-form ref="form" :model="form" :rules="formRules" class="disabled content bascform" :disabled="true" label-width="120px">
      <div v-if="fieldShow.materialDesc!==false" class="col col4">
        <el-form-item label="物料描述" prop="materialDesc">
            <el-input v-model="form.materialDesc"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.materialCode!==false" class="col col4">
        <el-form-item label="物料编码" prop="materialCode">
            <el-input v-model="form.materialCode"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-form ref="form" :model="form" :rules="formRules" class="disabled content bascform" :disabled="true" label-width="120px">
    <div class="col col4 header">原物料信息：</div>
    <div class="col col4 header">修改后物料信息：</div>
      <div v-if="fieldShow.oldMinBatch!==false" class="col col4">
        <el-form-item label="最小批量" prop="oldMinBatch">
            <el-input v-model="form.oldMinBatch"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.minBatch!==false" class="col col4">
        <el-form-item label="最小批量" prop="minBatch">
            <el-input v-model="form.minBatch"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.oldDeliveryTime!==false" class="col col4">
        <el-form-item label="LT交期" prop="oldDeliveryTime">
            <el-input v-model="form.oldDeliveryTime"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.deliveryTime!==false" class="col col4">
        <el-form-item label="LT交期" prop="deliveryTime">
            <el-input v-model="form.deliveryTime"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.oldIsHasBarcode!==false" class="col col4">
        <el-form-item label="收贴条码" prop="oldIsHasBarcode">
            <el-input v-model="form.oldIsHasBarcode"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.isHasBarcode!==false" class="col col4">
        <el-form-item label="收贴条码" prop="isHasBarcode">
            <el-input v-model="form.isHasBarcode"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.oldEcnType!==false" class="col col4">
        <el-form-item label="ECN类别" prop="oldEcnType">
            <el-input v-model="form.oldEcnType"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.ecnType!==false" class="col col4">
        <el-form-item label="ECN类别" prop="ecnType">
            <el-input v-model="form.ecnType"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.oldEcnUpdateDesc!==false" class="col col4">
        <el-form-item label="ECN变更描述" prop="oldEcnUpdateDesc">
            <el-input v-model="form.oldEcnUpdateDesc"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.ecnUpdateDesc!==false" class="col col4">
        <el-form-item label="ECN变更描述" prop="ecnUpdateDesc">
            <el-input v-model="form.ecnUpdateDesc"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <approval-mind></approval-mind>
    <div class="action" v-if="haveButton">
      <el-button @click="reject" class="save" type="primary" size="medium">驳回</el-button>
      <el-button  @click="pass" class="submit" type="primary" size="medium">通过</el-button>
      <el-button v-if="jq"  @click="apostille" class="submit" type="primary" size="medium">加签</el-button>
    </div>
  </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm, request } from '@/assets/js/http.js'
import { FlowCtlData, getApprPsn } from '@/assets/js/FlowManage.js'
export default {
  name: 'MaterialApproval',
  components: Component.components,
  data () {
    FlowCtlData.docid = ''
    return {
      haveButton: false,
      fieldShow: {},
      form: {},
      formRules: {},
      jq: false
    }
  },
  created () {
    var id = this.$route.query.id
    if (id) {
      this.requestData(id)
    }
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.id
        if (id) {
          this.requestData(id)
        }
      }
    }
  },
  methods: {
    reject () {
      FlowCtlData.getNodeAction('BH', this.form).then(
        (val) => {
          if (val) {
            FlowCtlData.alertBHDialog(this).then(({ value }) => {
              const approvalComplete = this.form.approvalComplete + localStorage.getItem('userName') + ','
              const approval = FlowCtlData.nextflowactionmsg.psnMsg.psnId
              requestForm('/api/supplier/material/updateApprovalPerson', 'put', { id: this.form.id, approval: approval, approvalComplete: approvalComplete, approvalStatus: 4 })
              requestForm('/api/supplier/material/approval', 'put', { id: this.form.id, type: 2 }).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction(value).then((val) => {
                    if (response.code === '200') {
                      this.$message({
                        message: '成功',
                        type: 'success'
                      })
                      window.history.back()
                    }
                  })
                }
              })
            })
          }
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    },
    pass () {
      this.form.sfsp = 'f'
      this.submit()
    },
    apostille () {
      this.form.sfsp = 'y'
      this.submit()
    },
    submit () {
      FlowCtlData.getNodeAction('TJ', this.form).then(
        (val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              const approvalComplete = this.form.approvalComplete + localStorage.getItem('userName') + ','
              const approval = FlowCtlData.nextflowactionmsg.psnMsg.psnId
              let approvalStatus = 2
              if (FlowCtlData.nextflowactionmsg.nodeMsg.nodeName === '结束') {
                approvalStatus = 3
              }
              requestForm('/api/supplier/material/updateApprovalPerson', 'put', { id: this.form.id, approval: approval, approvalComplete: approvalComplete, approvalStatus: approvalStatus }).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction().then((val) => {
                    if (val) {
                      if (FlowCtlData.nextflowactionmsg.nodeMsg.nodeName === '结束') {
                        requestForm('/api/supplier/material/approval', 'put', { id: this.form.id, type: 1 }).then((response) => {
                          if (response.code === '200') {
                            this.$message({
                              showClose: true,
                              message: '提交成功',
                              type: 'success'
                            })
                            window.history.back()
                          }
                        })
                      } else {
                        this.$message({
                          showClose: true,
                          message: '提交成功',
                          type: 'success'
                        })
                        window.history.back()
                      }
                    } else {
                      this.$message({
                        showClose: true,
                        message: '未找到审批人！',
                        type: 'error'
                      })
                    }
                  })
                }
              })
            }).catch(error => {
              console.log(error)
            })
          }
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    },
    requestData (id) {
      let st = 1
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.state == 2) {
        st = 2
      }
      request(`/api/supplier/material/getById/${id}/${st}`, 'get').then((Response) => {
        if (Response.code === '200') {
          this.form = Response.data
          FlowCtlData.flowid = 'MaterialChange'
          FlowCtlData.docid = Response.data.uuid
          FlowCtlData.initialize().then(() => {
            if (FlowCtlData.nodeThisData[0].NODEID === '4PZy6rdNWS7S7aB3QxBYp7rpCAtkf8mR') {
              this.jq = false
            } else {
              this.jq = true
            }
            if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
              this.haveButton = true
            } else {
              this.haveButton = false
            }
          })
          this.form.leader = ''
          getApprPsn('554180635259949056').then((val) => { this.form.leader = this.form.leader + val })
          getApprPsn('561052060826472448').then((val) => { this.form.leader = this.form.leader + val })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .action{
    margin-bottom: 20px;
  }
</style>
